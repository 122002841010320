import api from './api';

class UserRoleService {
  createUserRole = async (userRole : any) => {
    try {
      const response = await api.post('/userRole', userRole);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  getUserRoles = async (pageNumber?: number, count?: number) => {
    try {
      const response = await api.get(
        `/userRole?${count !== undefined ? (`count=${count}&`) : ''}`
      + `${pageNumber !== undefined ? (`pageNumber=${pageNumber}`) : ''}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  updateUserRole = async (id: string, userRole : any) => {
    try {
      const response = await api.put(`/userRole/${id}`, userRole);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  deleteUserRole = async (id: string) => {
    try {
      const response = await api.delete(`/userRole/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  getSearchResult = async (searchText: string, pageNumber?: number, count?: number) => {
    try {
      const response = await api.get(
        `/userRole/search?query=${encodeURIComponent(searchText)}&`
      + `${count !== undefined ? (`count=${count}&`) : ''}`
      + `${pageNumber !== undefined ? (`pageNumber=${pageNumber}`) : ''}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default new UserRoleService();
