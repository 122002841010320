const Routes = {
  home: '/',
  login: '/login',
  servicemonitoring:'/service-monitoring',
  apps: '/apps',
  settings: '/settings',
  kiteapi: '/kiteapi',
  users: '/users',
  userRoles: '/userRoles',
  notFound: '/404',
  notAuthorized: '/403',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:uid/:token',
  createPassword: '/create-password/:uid/:token',
};

export default Routes;
