import React, { useState } from 'react';
import md5 from 'md5';
import 'antd/dist/antd.css';
import { Layout, Form, Input, Button, Typography, Col, Row } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import authStore from '../../Store/authStore';
import Routes from '../../Global/Routes';
import OrganizationLogo from "../../Assets/Images/logo.svg";
import Utility from '../../Global/Utility';
import { UserOutlined, LockFilled } from '@ant-design/icons';
const Login = () => {
  const history = useHistory();
  const [loginError, setLoginError] = useState('');

  const onFinish = (values: any) => {
    const { email, password } = values;
    const passwordEncrypted = md5(password);
    authStore.signIn(email, passwordEncrypted, (err: any) => {
      if (err) {
        setLoginError(err);
        return;
      }
      history.push(Routes.home);
    });
  };

  return (
    <Layout.Content id='login-container' className='h-100'>
      <Row className="h-100">
        <Col xs={0} md={12} lg={13} xl={12} className="login-bg">
          <div className="green-circle">
            <div className="white-circle">
            <img
        src={OrganizationLogo}
        // className='logo__brand'
        alt='OrganisationLogo'
        // width={35}
        // height={35}
      />
            </div>
          </div>
        </Col>
        <Col xs={24} md={12} lg={11} xl={12} className='sso-login-pwd'>
        <div className='hero'>
        <div className='hero_text'>
          <div className='login-page-content'>
            <div className='login-form'>
              <div className='d-flex text-center mb-4 justify-content-center align-items-center'>
                <h2 className="font-weight-bold">Login</h2>
     
      </div>
      <Form 
        name='normal_login' 
        className='d-flex flex-column'
        layout='vertical' 
        onFinish={onFinish}
      >
         <div className="form-group md">
        <Form.Item
          name='email'
          // label="Email"
          className="mb-3"
          normalize={(value) => Utility.trimSpacesInValues(value)}
          rules={[
            {
              required: true,
              message: 'Please enter the email address.',
            },
          ]}
        >
          <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
            className='custom-input w-100'            
            placeholder='Email'
            onChange={() => setLoginError('')}
          />
        </Form.Item>
        <Form.Item
          name='password'
          // label="Password"
          className="mb-3"
          normalize={(value) => Utility.trimSpacesInValues(value)}
          rules={[
            {
              required: true,
              message: 'Please enter your Password.',
            },
          ]}
        >
          <Input.Password
          prefix={<LockFilled className="site-form-item-icon" />}
            className='custom-input'
            type='password'
            placeholder='Password'
            onChange={() => setLoginError('')}
          />
        </Form.Item>
        <p className='font-light-300 text-right'>
                      <Link
                        to={Routes.forgotPassword}
                        className='font-weight-medium'
                      >
                        Forgot Password?{' '}
                      </Link>
                    </p>
        </div>
        <Form.Item className="text-center">
          <Button
            className="md px-5 w-100"
            type='primary'
            htmlType='submit'
            shape="round"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
      {loginError && (
        <Typography.Text type='danger' className='mb-2 d-inline-block'>
          {loginError}
        </Typography.Text>
      )}
      </div>
            </div>
          </div>
        </div>
        </Col>
        </Row>
      
    </Layout.Content>
  );
};


export default Login;