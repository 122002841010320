import React, { useState } from 'react';
import {
  useMutation,
  queryCache,
  usePaginatedQuery,
  useQuery,
} from 'react-query';
import { observer } from 'mobx-react';
import {
  Layout,
  Typography,
  Table,
  Button,
  Modal,
  Form,
  Space,
  Spin,
} from 'antd';
import {
  ExclamationCircleOutlined,
  EditFilled,
  DeleteFilled
} from '@ant-design/icons';
import notification from '../../Global/Notification';
import Constant from '../../Global/Constant';
import AddUserModal from './AddUserModal';
import userService from '../../Services/userService';
import userRoleService from '../../Services/userRoleService';
import Searchbox from '../../Components/Search';
const { Title } = Typography;
const fetchUsers = async (
  key: string,
  pageNumber: number,
  searchText: string
) => {
  if (searchText === '') {
    const response = await userService.getUsers(
      pageNumber,
      Constant.itemsPerPage
    );
    return { users: response.data.users, count: response.data.total };
  }
  const response = await userService.getSearchResult(
    searchText,
    pageNumber,
    Constant.itemsPerPage
  );
  return {
    users: response.data.result.users,
    count: response.data.result.total,
  };
};

const createOrUpdateUser = async (newUser: any) => {
  if (newUser.id) {
    const response = await userService.updateUser(newUser.id, newUser);
    return response.data.message;
  }
  const response = await userService.createUser(newUser);
  return response.data.message;
};

const deleteUser = async (id: string) => {
  const response = await userService.deleteUser(id);
  return response.data.message;
};

const fetchRoles = async () => {
  const res = await userRoleService.getUserRoles();
  return res.data.roles;
};

const Users = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [buttonLoading, setModalButtonLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { resolvedData, status } = usePaginatedQuery(
    ['users', pageNumber, searchText.trim()],
    fetchUsers
  );
  const { data: roles } = useQuery('roles', fetchRoles, {
    onSuccess: (success: any) => {
      onMutateSuccess('getUsers', success);
    },
    onError: (error: any) => {
      onMutateError(error);
    },
  });
  const [createOrUpdateMutate] = useMutation(createOrUpdateUser, {
    onSuccess: (success: any) => {
      onMutateSuccess('createOrUpdate', success);
    },
    onError: (error: any) => {
      onMutateError(error);
    },
  });

  const [deleteMutate] = useMutation(deleteUser, {
    onSuccess: (success: any) => {
      onMutateSuccess('delete', success);
    },
    onError: (error: any) => {
      onMutateError(error);
    },
  });

  const onMutateSuccess = (isFrom: string, success: any) => {
    if (isFrom === 'createOrUpdate' || isFrom === 'delete') {
      queryCache.refetchQueries('users');
      if (isFrom === 'delete') {
        const lastPageNumber = Math.ceil(
          (resolvedData?.count - 1) / Constant.itemsPerPage
        );
        setPageNumber(
          pageNumber < lastPageNumber ? pageNumber : lastPageNumber
        );
      }
      if (isFrom === 'createOrUpdate') {
        setPageNumber(
          editMode
            ? pageNumber
            : Math.ceil((resolvedData?.count + 1) / Constant.itemsPerPage)
        );
      }
      setModalVisibility(false);
      setModalButtonLoading(false);
    }
  };

  const onMutateError = (error: any) => {
    setModalButtonLoading(false);
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      notification.error({
        description: error.response.data.error.message,
        message: 'Error',
      });
    }
  };

  const [form] = Form.useForm();
  const { confirm } = Modal;
  const viewUserModal = (isEditMode: boolean, record?: any) => {
    setEditMode(isEditMode);
    form.resetFields();
    form.setFieldsValue(record || {});
    form.setFieldsValue({ roleId: record?.role?.id });
    form.setFields([
      { touched: false, name: 'id' },
      { touched: false, name: 'name' },
      { touched: false, name: 'email' },
      { touched: false, name: 'roleId' },
    ]);
    setModalVisibility(true);
  };

  const deleteUserOnConfirm = (id: string) => {
    deleteMutate(id);
  };

  const viewDeleteConfirmModal = (id: string) => {
    confirm({
      title: ' ',
      content: Constant.deleteUserContent,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteUserOnConfirm(id);
      },
      okText: 'Yes',
      cancelText: 'No',
    });
  };

  const tableColumns = [
    {
      title: '#',
      key: 'key',
      render: (record: any) => (
        <span>
          {resolvedData && resolvedData.users.length > 10
            ? resolvedData && resolvedData.users.indexOf(record) + 1
            : (resolvedData && resolvedData.users.indexOf(record) + 1) +
              Constant.itemsPerPage * (pageNumber - 1)}
        </span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Role',
      dataIndex: ['role', 'role'],
      key: 'role',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="large">
        <EditFilled onClick={() => viewUserModal(true, record)} />
        <DeleteFilled onClick={() => viewDeleteConfirmModal(record.id)} />
        </Space>
      ),
    },
  ];

  const saveData = () => {
    form.validateFields().then((value) => {
      setModalButtonLoading(true);
      if (editMode) {
        const modifiedData = form.getFieldsValue(
          Object.keys(value).filter((x: string) => form.isFieldTouched(x))
        );

        if (Object.keys(modifiedData).length > 0) {
          createOrUpdateMutate(value);
        } else {
          setModalVisibility(false);
          setModalButtonLoading(false);
        }
      } else {
        createOrUpdateMutate(value);
      }
    });
  };

  const onSearchText = (currentSearchText: string) => {
    if (searchText !== currentSearchText) {
      setSearchText(currentSearchText);
      setPageNumber(Constant.defaultPageNumber);
    }
  };

  const handleSearchTextChange = (value: string) => {
    if (value.length === 0) {
      onSearchText('');
    }
  };

  const setButtonLoading = (value: boolean) => {
    setModalButtonLoading(value);
  };

  return (
    <Layout.Content>
      <Spin spinning={status === 'loading'}>
        <div className='container custom-container'>
          <div className='d-flex sm-flex-direction-column'>
            <div className='flex-fill content-title mb-3'>
              <Title level={4}> Users</Title>
              <div className='title-line'></div>
            </div>
            <div className='flex-auto'>
              <div className='top-bar d-flex mb-3'>
                <div className='d-inline-block mr-2 pr-2 border-right'>
                  <Searchbox
                    onSearch={onSearchText}
                    onChange={handleSearchTextChange}
                  />
                </div>
                <div className='d-inline-block'>
                  <Button
                    className='sm'
                    type='primary'
                    onClick={() => viewUserModal(false)}
                    disabled={status === 'loading'}
                  >
                    <span className='icon-12 mr-2'>
                      <svg>
                        <use xlinkHref='#add'></use>
                      </svg>
                    </span>{' '}
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='position-relative user-wrapper'>
            <Table
              className={'pb-5 bg-white'}
              columns={tableColumns}
              rowKey={(record) => record.id}
              pagination={
                resolvedData?.count > Constant.itemsPerPage && {
                  pageSize: Constant.itemsPerPage,
                  position: ['bottomCenter'],
                  total: resolvedData?.count,
                  current: pageNumber,
                  onChange: (currentPageNumber: number) => {
                    setPageNumber(currentPageNumber);
                  },
                  showSizeChanger: false,
                }
              }
              dataSource={resolvedData && resolvedData.users}
            />
          </div>
          <AddUserModal
            editMode={editMode}
            form={form}
            onSave={saveData}
            onClose={() => setModalVisibility(false)}
            modalVisibility={modalVisibility}
            userRoles={roles || []}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />
        </div>
      </Spin>
    </Layout.Content>
  );
};

export default observer(Users);
