/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import {
  Login,
  App,
  KiteApi,
  NotFound,
  NotAuthorized,
  Settings, 
  Users,
  UserRole,
  ForgotPassword,
  ResetPassword
} from '../../Pages';
import PublicRoute from '../PublicRoute';
import PrivateRoute from '../PrivateRoute';
import AccessRoute from '../AccessRoute';
import Constant from '../../Global/Constant';
import Routes from '../../Global/Routes';
import authStore from '../../Store/authStore';
import { Header } from '../../Components';
import FooterComponent from '../../Components/Footer/index';
import ServiceMonitoring from '../../Pages/ServiceMonitoring';

const {
  appAccess, users, userRoles, serviceMonitoring, kiteApi, ADSettings, serviceMonitoringAction
} = Constant.userAccess;

const getaccessRoute = () => {
  const { currentUser } = authStore;
  let redirectTo = Routes.notFound;
  if (currentUser && (currentUser.userAccess.includes(appAccess))) {
    redirectTo = Routes.apps;
  }
  else if (currentUser && (currentUser.userAccess.includes(kiteApi))) {
    redirectTo = Routes.kiteapi;
  }
  else if (currentUser && (currentUser.userAccess.includes(ADSettings))) {
    redirectTo = Routes.settings;
  }
  else if (currentUser && ((currentUser.userAccess.includes(serviceMonitoring)) || (currentUser.userAccess.includes(serviceMonitoringAction)))) {
    redirectTo = Routes.servicemonitoring;
  }
  else if (currentUser && (currentUser.userAccess.includes(users))) {
    redirectTo = Routes.users;
  }
  else if (currentUser && (currentUser.userAccess.includes(userRoles))) {
    redirectTo = Routes.userRoles;
  }
  else {
    redirectTo = Routes.notAuthorized;
  }
  return redirectTo;
};

const MainComponent = () => (
  <Layout>
    <Header />
    <Layout>
      <Layout.Content className="body-container">
        <Switch>
          <Route path={Routes.home} exact>
            <Redirect to={getaccessRoute()} />
          </Route>
          <AccessRoute
            path={Routes.servicemonitoring}
            component={ServiceMonitoring}
            access={[serviceMonitoring, serviceMonitoringAction]}
            exact
          />
          <AccessRoute
            path={Routes.apps}
            component={App}
            access={[appAccess]}
            exact
          />
          <AccessRoute
            path={Routes.settings}
            component={Settings}
            access={[ADSettings]}
            exact
          />
          <AccessRoute
            path={Routes.kiteapi}
            component={KiteApi}
            access={[kiteApi]}
            exact
          />
          <AccessRoute
            path={Routes.notAuthorized}
            component={NotAuthorized}
            access={[appAccess]}
            exact
          /> 
          <AccessRoute
            path={Routes.users}
            component={Users}
            access={[users]}
            exact
          /> 
            <AccessRoute
            path={Routes.userRoles}
            component={UserRole}
            access={[userRoles]}
            exact
          /> 
          <Redirect to={Routes.notFound} />
        </Switch>
      </Layout.Content>
    </Layout>
    <FooterComponent/>
  </Layout>
);

const Router = () => (
  <Switch>
    <PublicRoute exact path={Routes.login} component={Login} restricted />
    <PublicRoute
      exact
      path={Routes.forgotPassword}
      component={ForgotPassword}
    />
    <PublicRoute
      exact
      path={Routes.resetPassword}
      component={ResetPassword}
    />
    <PublicRoute
      exact
      path={Routes.createPassword}
      component={ResetPassword}
    />
    <Route path={Routes.notFound} component={NotFound} exact />
    <PrivateRoute path={Routes.home} component={MainComponent} />
  </Switch>
);

export default Router;
