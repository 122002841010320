const Constant = {
  token: 'token',
  refreshToken: 'refreshToken', 

  defaultPageNumber: 1,
  itemsPerPage: 10,
  notificationDuration: 3,

  defaultErrorMessage: 'Something went wrong, please try again!',
  unAuthorizedError: 'You are not authorized to access this website.',

  userAccess: {
    appAccess: 1,
    users: 2,
    userRoles: 3,
    serviceMonitoring: 4,
    kiteApi: 5,
    ADSettings: 6,
    serviceMonitoringAction: 7,
  },

  userRoles: {
    SuperAdmin: 1,
    Management: 2,
    DeliveryHead: 3,
    ProjectManager: 4,
    TeamLead: 5,
    HRManager: 6,
    TAEmployee: 7,
    SystemAdmin: 8,
    Guest:9,
    ContractEmployee:10
  },

  appScope: {
    Project: 1,
    User: 2,
    Timesheet: 3,
    Client: 4,
    Asset: 5,
    Location: 6,
    BusinessUnits: 7,
    Holidays: 8,
    'Create User': 9,
    'Upload Document': 10,
    Currency: 11
  },

  deleteModalTitle: 'Warning',
  deleteModalContent: 'Do you want to delete this app?',
  deleteUserContent: 'Do you want to delete this user?',
  userNameError: 'Please enter valid name of maximum length 20.',
  whiteSpaceError: 'Space cannot be entered.',
  emailValidationError:
  'Please enter a valid email address not more than 50 characters.',
  emailRequiredValidationError: 'Please enter the email address.',
  roleRequired: 'Please select a role.',
  resetPasswordWarning: 'Are you sure you want to reset the password?',
  deleteUserRoleContent: 'Do you want to delete this user role?',
  userRoleError: 'Please enter a valid role of maximum length 20.',
  userAccessError: 'At least one user access is required.',
  maxLengthOfName: 20,
  emailMaxLength: 50,
  emailMinLength: 3,
  maxLengthOfRole: 20,
  deleteServiceContent: 'Do you want to delete this?',
  newPasswordRequiredError: 'Enter your new password.',
  passwordValidationError:
    'Password must contain at least one uppercase, one lower case, one numeral, one special character and have a minimum of 6 characters.',
  confirmPasswordRequiredError: 'Please confirm your password.',
  passwordMatchError: 'Password do not match!',
};

export default Constant;
