import React, { useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  Layout,
  Typography,
  Form,
  Input,
  Button,
  Row,
  Col,
} from 'antd';
import OrganizationLogo from '../../Assets/Images/logo.svg';
import './ResetPassword.less';
import notification from '../../Global/Notification';
import Constant from '../../Global/Constant';
import authStore from '../../Store/authStore';
import Routes from '../../Global/Routes';
import Utility from '../../Global/Utility';
import '../../Assets/scss/pages/_password.scss'

const md5 = require("md5");
function ResetPassword() {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split('/');
  const routeName = path[1];

  const { uid, token } = useParams<{uid:string,token:string}>();

  const resetPasswordHandler = (values: any) => {
    setLoading(true);
    authStore.resetPassword(md5(values.password), uid, token, (err?: Error) => {
      setLoading(false);
      if (err) {
        notification.error({
          description: err.message,
          message: 'Error',
        });
        return;
      }
      history.push(Routes.login);
      authStore.signOut()
    });
  };

  return (
    <Layout.Content id="reset-password" className="h-100">
      <Row className="h-100">
        <Col xs={0} md={12} lg={13} xl={12} className="password-bg">
          <div className="green-circle">
            <div className="white-circle">
              <img
                src={OrganizationLogo}
                // className='logo__brand'
                alt='OrganisationLogo'
              // width={35}
              // height={35}
              />
            </div>
          </div>
        </Col>
        <Col xs={24} md={12} lg={11} xl={12}  className='sso-login-pwd'>
        <div className="box">
        <div className="text-center">
          <Typography.Title className="my-4 sso-title" level={3}>
            {routeName === 'reset-password' ? 'Reset Password' : 'Create Password'}
          </Typography.Title>
        </div>
        <div className="rounded px-4 py-5">
          <Form onFinish={resetPasswordHandler}>
            <Form.Item
              name="password"
              className="mb-3"
              hasFeedback
              normalize={(value) => Utility.trimSpacesInValues(value)}
              rules={[
                {
                  required: true,
                  message: Constant.newPasswordRequiredError,
                },
                () => ({
                  validator(rule, value) {
                    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;
                    if (!value || value.match(pattern)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(Constant.passwordValidationError));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="New password" className='custom-input'/>
            </Form.Item>
            <Form.Item
              name="confirm_password"
              className="mb-3"
              dependencies={['password']}
              hasFeedback
              normalize={(value) => Utility.trimSpacesInValues(value)}
              rules={[
                {
                  required: true,
                  message: Constant.confirmPasswordRequiredError,
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(Constant.passwordMatchError));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm new password" className='custom-input' />
            </Form.Item>
            <Form.Item className="text-center">
            <Button type="primary" htmlType="submit" loading={loading}  className="md px-5"  block>
              {routeName === 'reset-password' ? 'Reset Password' : 'Create Password'}
            </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default ResetPassword;
