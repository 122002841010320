import api from './api';

class UserService {
  getUsers = async (pageNumber?: number, count?: number) => {
    try {
      const response = await api.get(
        `/user?${count !== undefined ? (`count=${count}&`) : ''}`
      + `${pageNumber !== undefined ? (`pageNumber=${pageNumber}`) : ''}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  createUser = async (user : any) => {
    try {
      const response = await api.post('/user', user);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  updateUser = async (id: string, user : any) => {
    try {
      const response = await api.put(`/user/${id}`, user);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  deleteUser = async (id: string) => {
    try {
      const response = await api.delete(`/user/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  getSearchResult = async (searchText: string, pageNumber?: number, count?: number) => {
    try {
      const response = await api.get(
        `/user/search?query=${encodeURIComponent(searchText)}&`
      + `${count !== undefined ? (`count=${count}&`) : ''}`
      + `${pageNumber !== undefined ? (`pageNumber=${pageNumber}`) : ''}`,
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  resendPassword = async (data: any) => api.post('/user/resendPassword', data);
}

export default new UserService();
