import React, { useEffect, useState } from 'react';
import {
  Layout,
  Typography,
  Button,
  Modal,
  Input,
  Space,
  Spin, 
  Tag,
  Card,
  Col,
  Row,
  Pagination,
  Form,
} from 'antd';
import { DeleteFilled, EditFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import AddServiceModal from './AddServiceModal';
import Text from 'antd/lib/typography/Text';
import { Footer } from 'antd/lib/layout/layout';
import serviceMonitoringService from '../../Services/serviceMonitoringService';
import Constant from '../../Global/Constant';
import moment from 'moment';
import authStore from '../../Store/authStore';

const { Search } = Input;

function ServiceMonitoring() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [appNamesArray, setAppNamesArray] = useState<any>([]);
  const [serviceMonitoringDatas, setServiceMonitoringDatas] = useState([]);
  const [filteredData, setFilteredData] = useState([]); 
  const [editId, setEditId] = useState();
  const [initialValues, setInitialValues] = useState({
    minute: '',
    hour: '',
    month: '',
    dayWeek: '',
    dayMonth: ''
})
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 9; 
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = serviceMonitoringDatas.slice(startIndex, endIndex);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };       

  useEffect(() => {
    getAllServiceMonitoringDatas();
    getAllApps();
  },[])

  const getAllApps = async () => {
    const response = await serviceMonitoringService.getApps();
    setAppNamesArray(response.data.appNames);
  }

  const getAllServiceMonitoringDatas = async () => {
    const response = await serviceMonitoringService.getServiceMonitoringDatas();
    setFilteredData(response?.data?.getServiceMonitoringDatas);
    setServiceMonitoringDatas(response?.data?.getServiceMonitoringDatas);
  }

  const handleAdd = () => {
    setInitialValues({
      minute: '',
      hour: '',
      month: '',
      dayWeek: '',
      dayMonth: ''
    })
    form.setFieldsValue({
      appName: '',
      moduleName: '',
      moduleId: ''
    });
    setSelectedRecord(null);
    setIsEditMode(false);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleEdit = (data: any) => {
    setEditId(data.id);
    setIsEditMode(true);
    setIsModalOpen(true);
    setTimeout(() => {
      form.setFieldsValue({
        appName: data?.appName,
        moduleName: data?.moduleName,
        moduleId: data?.moduleId
      });
      setInitialValues({
        minute: data?.frequencyMin,
        hour: data?.frequencyHour,
        month: data?.frequencyMonth,
        dayWeek: data?.frequencyDayWeek,
        dayMonth: data?.frequencyDayMonth
      })
  }, 0); 
  }

  const handleDelete = async (id: any) => {
    const response = await serviceMonitoringService.deleteServiceMonitoring(id);
    if(response){
      getAllServiceMonitoringDatas();
    }
  }

  const confirmDeletion = (id : string) => {
    Modal.confirm({
      title: Constant.deleteModalTitle,
      icon: <ExclamationCircleOutlined />,
      content: Constant.deleteServiceContent,
      okText: 'Yes',
      cancelText: 'No',
      onOk() { handleDelete(id); },
    });
  };

  const onSearchChange = (e: any) => {
    const searchValue = e.target.value.toLowerCase(); 
    const filteredDataValue = filteredData.filter((item: any) => 
      item.moduleName.toLowerCase().includes(searchValue) 
    );
    setServiceMonitoringDatas(filteredDataValue);
  }

  return (
    <Layout.Content>
      <Spin spinning={listLoading}>
        <div className="container responsive-container">
          <div className="d-flex flex-column flex-sm-row mb-3">
            <div className="flex-fill content-title mb-3">
              <Typography.Title level={4}>Service Monitoring List</Typography.Title>
              <div className="title-line"></div>
            </div>
            <div className="flex-auto">
              <div className="top-bar d-flex mb-3">
                <div className="d-inline-block mr-2 pr-2 border-right">
                  <Search
                    placeholder="Search"
                    className="ml-2"
                    style={{ width: 343 }}
                    allowClear
                    onChange={(e: any) => onSearchChange(e)}
                  />
                </div>
                {authStore?.currentUser?.userAccess.includes(Constant.userAccess.serviceMonitoringAction) &&
                <div className="d-inline-block add-btn-style">
                  <Button className="sm" type="primary" onClick={handleAdd}>
                    Add
                  </Button>
                </div>
                }
              </div>
            </div>
          </div>
          <div className="position-relative">
            <Row gutter={[16, 16]}>
              {currentData.map((data: any) => (
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <Card
                    className={data.nextSync && new Date(data.nextSync) < new Date()? "responsive-card red-card": "responsive-card"}
                    bordered={false}
                    title={
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span style={{ fontWeight: 'bold' }}>{(appNamesArray.filter((value: any) =>value.id == data?.appName)[0])?.appName}</span>
                        {authStore?.currentUser?.userAccess.includes(Constant.userAccess.serviceMonitoringAction) &&
                        <div>
                          <EditFilled style={{ marginRight: 8, cursor: 'pointer' }} onClick={() => handleEdit(data)}/>
                          <DeleteFilled style={{ cursor: 'pointer' }} onClick={() => confirmDeletion(data.id)}/>
                        </div>
                        }
                      </div>
                    }
                  >
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '8px' }}>
                      <Text className='service-label'>
                      Module Name
                      </Text>
                      <Text className='service-detail'>{data?.moduleName}</Text>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '8px' }}>
                      <Text className='service-label' >
                        Last Sync
                      </Text>
                      {data?.lastSync && data?.nextSync ? (
                        new Date(data.nextSync) < new Date() ? (
                          <Text style={{ color: '#CF1322' }}>
                            {moment(data.lastSync).format("MMMM D, h:mm A dddd")}
                          </Text>
                        ) : (
                          <Text className='sso-monitoring-cyan'>
                            {moment(data.lastSync).format("MMMM D, h:mm A dddd")}
                          </Text>
                        )
                      ) : null}
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '8px' }}>
                      <Text className='service-label' >
                        Next Sync
                      </Text>
                      {data.nextSync && <Text className='service-detail'>{data.nextSync ? moment(data.nextSync).format("MMMM D, h:mm A dddd") : ""}</Text>}
                    </div>
                  </Card>
                </Col>
              ))}
              
            </Row>
          </div>
          <Footer style={{ textAlign: 'center' }}>
          {(serviceMonitoringDatas.length > pageSize) && <Pagination
          current={currentPage}
          total={serviceMonitoringDatas.length}
          pageSize={pageSize}
          onChange={onPageChange}
        />}
          </Footer>
          <AddServiceModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            isEditMode={isEditMode}
            record={selectedRecord}
            appNamesArray={appNamesArray}
            getAllServiceMonitoringDatas={getAllServiceMonitoringDatas}
            form={form}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            editId={editId}
          />
        </div>
      </Spin>
    </Layout.Content>
  );
}

export default ServiceMonitoring;
