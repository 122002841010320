/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import api from './api';

class AuthService {
  login = async (data : any) => api.post('/user/login', data);
  forgotPasswordRequest = async (email: string) => api.post('/request/resetPassword', { email });
  resetPassword = async (data: any) => api.post('/request/changePassword', data);
}

export default new AuthService();
