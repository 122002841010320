import React from 'react';
import { Layout, Typography, Popconfirm } from 'antd';
import authStore from '../../Store/authStore';
import { Link, useHistory } from 'react-router-dom';
import Routes from '../../Global/Routes';
import OrganizationLogo from "../../Assets/Images/logo.svg";
import Constant from '../../Global/Constant';

function Header() {
  const history = useHistory();

  return (
    <Layout.Header id='header'>
      <Layout.Content>
        <Typography.Text strong>
          <div className='d-flex align-items-center'>
            <div className='flex-fill'>
              <div className='logo-container'>
                <div
                  className='mb-0 d-flex align-items-center cursor-pointer'
                  onClick={() => { history.push(Routes.apps) }}
                >
                  <img src={OrganizationLogo} alt="logo" className="mr-2" />
                </div>
              </div>
            </div>
            <div className="flex-auto">
              <ul className="header-menu">
                {authStore?.currentUser?.userAccess.includes(Constant.userAccess.appAccess) && <li><a><Link to={Routes.apps}  className="font-weight-medium">Apps</Link></a></li>}
                {authStore?.currentUser?.userAccess.includes(Constant.userAccess.kiteApi) && <li><a><Link to={Routes.kiteapi} className="font-weight-medium">Kite API</Link></a></li>}
                {authStore?.currentUser?.userAccess.includes(Constant.userAccess.ADSettings) && <li><a><Link to={Routes.settings} className="font-weight-medium">AD Settings</Link></a></li>}
                {((authStore?.currentUser?.userAccess.includes(Constant.userAccess.serviceMonitoring)) || (authStore?.currentUser?.userAccess.includes(Constant.userAccess.serviceMonitoringAction))) && <li><a><Link to={Routes.servicemonitoring} className="font-weight-medium">Service Monitoring</Link></a></li>}
                {authStore?.currentUser?.userAccess.includes(Constant.userAccess.users) && <li><a><Link to={Routes.users} className="font-weight-medium">Users</Link></a></li>}
                {authStore?.currentUser?.userAccess.includes(Constant.userAccess.userRoles) && <li><a><Link to={Routes.userRoles} className="font-weight-medium">User Roles</Link></a></li>}
                <li className="font-weight-medium" onClick={(e) => e.preventDefault()}>{authStore?.currentUser?.name}</li>
              </ul>
            </div>

            <div className="flex-auto logout">
              <Popconfirm
                placement="bottomRight"
                title="Are you sure you want to logout?"
                onConfirm={() => authStore.signOut()}
                okText="Yes"
                cancelText="No"
              >
                <div>
                  <span className="icon-20 ml-3">
                    <svg>
                      <use xlinkHref="#logout"></use>
                    </svg>
                  </span>
                </div>
              </Popconfirm>
            </div>
          </div>
        </Typography.Text>
      </Layout.Content>
    </Layout.Header>
  );
}

export default Header;
