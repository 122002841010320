/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import {
  Layout,
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
} from 'antd';
import { useHistory } from 'react-router-dom';
import notification from '../../Global/Notification';
import OrganizationLogo from '../../Assets/Images/logo.svg';
import './ForgotPassword.less';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import authStore from '../../Store/authStore';
import Utility from '../../Global/Utility';
import '../../Assets/scss/pages/_password.scss'

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [emailSend, setEmailSend] = useState(false);
  const history = useHistory();

  const sendEmailHandler = (values: any) => {
    setLoading(true);
    authStore.forgotPasswordRequest(values.email, (err?: Error) => {
      setLoading(false);
      if (err) {
        notification.error({
          description: err.message,
          message: 'Error',
        });
        return;
      }
      setEmailSend(true);
    });
  };

  const returnHandler = () => {
    history.push(Routes.login);
  };

  return (
    <Layout.Content id='forgot-container' className='h-100'>
      <Row className="h-100">
        <Col xs={0} md={12} lg={13} xl={12} className="password-bg">
          <div className="green-circle">
            <div className="white-circle">
              <img
                src={OrganizationLogo}
                // className='logo__brand'
                alt='OrganisationLogo'
              // width={35}
              // height={35}
              />
            </div>
          </div>
        </Col>
        <Col xs={24} md={12} lg={11} xl={12}  className='sso-login-pwd'>
        <div className="box">
        <div className="text-center">
          <Typography.Title className="my-4 sso-title" level={3}>
            Forgot Password 
          </Typography.Title>
        </div>
        <div className="rounded px-4 py-5">
          {
            !emailSend ? (
              <Form onFinish={sendEmailHandler}>
                <Form.Item
                  name="email"
                  className="mb-4"
                  normalize={(value) => Utility.trimSpacesInValues(value)}
                  rules={[
                    {
                      type: 'email',
                      max: Constant.emailMaxLength,
                      min: Constant.emailMinLength,
                      message: Constant.emailValidationError,
                    },
                    {
                      required: true,
                      message: Constant.emailRequiredValidationError,
                    },
                    {
                      whitespace: true,
                      message: Constant.whiteSpaceError,
                    },
                  ]}
                >
                  <Input placeholder="Enter email address" className='custom-input' />
                </Form.Item>
                <div className="">
                  <Button type="primary" htmlType="submit" loading={loading} block className="md px-5">
                  Reset Password
                  </Button>
                  <Button className="mt-4 md px-5 sso-cancel-btn" disabled={loading} onClick={returnHandler} block
            >Cancel</Button>
                </div>
              </Form>
            )
              : (
                <div className="">
                  <Typography.Paragraph className="font-weight-bold mb-3">
                    Check your email for a link to reset your password.
                  </Typography.Paragraph>
                  <Button type="primary" className="w-100" onClick={returnHandler}>
                    Return to login
                  </Button>
                </div>
              )
          }
        </div>
      </div>
        </Col>
      </Row>

    </Layout.Content>

  );
}

export default ForgotPassword;
