import { makeObservable, observable } from 'mobx';
import Constant from '../Global/Constant';
import authService from '../Services/authService';
import Utility from '../Global/Utility';

type userType = {
  uid: string;
  name: string;
  email: string;  
  userAccess: Array<any>;
  expiryTime: number;
  phoneNumber: string;
  profilePic: string;
};

class AuthStore {
  error = '';
  authListener: ((user: any) => void) | null = null;
  currentUser: userType | null = null;
  refreshExpireTime: any;
  search = '';
  currentPage = Constant.defaultPageNumber;


  constructor () {
    makeObservable(this, {
      error: observable,
      search: observable,
      currentPage: observable
    });
  }

  addAuthListener(callbackListener: (user: any) => void) {
    this.authListener = callbackListener;
    this.currentUser = Utility.getCurrentUser();
    this.refreshExpireTime = Utility.getRefreshTokenTime();
    if (
      this.currentUser &&
      this.refreshExpireTime &&
      this.refreshExpireTime < new Date().getTime()
    ) {
      this.signOut();
    } else if (this.currentUser) {
      if (this.authListener) {
        this.authListener(this.currentUser);
      }
    } else if (this.authListener) {
      this.authListener(this.currentUser);
    }
  }

  containsRequiredUserRoles(userAccess: Array<number>) {
    if(userAccess === undefined){
      return false
    }
    
    const {
      userRoles,
      users,
      appAccess,
      kiteApi,
      ADSettings,
      serviceMonitoring
    } = Constant.userAccess;

    if (
      [
      userRoles,
      users,
      appAccess,
      kiteApi,
      ADSettings,
      serviceMonitoring
      ].filter((val: any) => userAccess.includes(val)).length > 0
    ) {
      return true;
    }
    return false;
  }

  signIn(email: string, password: string, callback: any) {
    authService
      .login({ email, password })
      .then((resp: any) => {
        if (resp && resp.data) {
          const { token, refreshToken } = resp.data;
          const user = Utility.parseJwt(token);

          if (this.containsRequiredUserRoles(user.userAccess)) {
            localStorage.setItem(Constant.token, token);
            localStorage.setItem(Constant.refreshToken, refreshToken);

            this.currentUser = user;
            callback(null);
          } else {
            callback(Constant.unAuthorizedError);
          }
        }
      })
      .catch((err) => {
        let errorMsg = Constant.defaultErrorMessage;
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          errorMsg = err.response.data.error.message;
        }
        callback(errorMsg);
      });
  }
  signOut() {
    localStorage.removeItem(Constant.token);
    localStorage.removeItem(Constant.refreshToken);
    this.currentUser = null;
    if (this.authListener) {
      this.authListener(this.currentUser);
    }
  }

  async forgotPasswordRequest(email: string, callback: (err?: Error) => void) {
    try {
      await authService.forgotPasswordRequest(email);
      callback();
    } catch (err: any) {
      let errorMsg = Constant.defaultErrorMessage;
      if (err && err.response && err.response.data && err.response.data.error) {
        errorMsg = err.response.data.error.message;
      }
      callback(new Error(errorMsg));
    }
  }

  async resetPassword(
    password: string,
    userId: string,
    token: string,
    callback: (err?: Error) => void
  ) {
    try {
      const data = { password, token, userId };
      await authService.resetPassword(data);
      callback();
    } catch (err: any) {
      let errorMsg = Constant.defaultErrorMessage;
      if (err && err.response && err.response.data && err.response.data.error) {
        errorMsg = err.response.data.error.message;
      }
      callback(new Error(errorMsg));
    }
  }
}

export default new AuthStore();
