import React from 'react'
import { Input } from "antd";

const { Search } = Input;
interface SearchProps{
  onSearch: (value: string) => void
  onChange: (event: any) => void
  
}

function Searchbox({onSearch,onChange}: SearchProps) {
    return (
        <div>
           <Search
                  placeholder="Search"
                  onChange={onChange}
                  onSearch={onSearch}
                  allowClear={true}
                /> 
        </div>
    )
}

export default Searchbox

